import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import * as actionsCore from "../../actions/core";

import * as routesPage from "../../constants/page"

import "./style.scss"

class SettingPage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={"setting-page"}>
                <Typography className={"base-h4"} variant={"h4"}>Настройки</Typography>
                <Button fullWidth={true} variant={"contained"} color={"secondary"} onClick={() => {
                    this.props.setLink(routesPage.setting.history);
                }}>История бронирований</Button>
                <br />
                <br />
                <Button fullWidth={true} variant={"contained"} color={"secondary"} onClick={() => {
                    this.props.setLink(routesPage.setting.profile);
                }}>Профиль</Button>
                <br />
                <br />
                <Button fullWidth={true} variant={"contained"} color={"default"} onClick={() => {
                    this.props.setShowLogout(true);
                }}>Выйти</Button>
                <br />
                <br />
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
    }
};


const mapDispatchToProps = (dispatch) => {
    return {
        setLink: bindActionCreators(actionsCore.setLink, dispatch),
        setShowLogout: bindActionCreators(actionsCore.setShowLogout, dispatch),
    }
};

export default (connect(mapStateToProps, mapDispatchToProps)(SettingPage))