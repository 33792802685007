import {fetching} from "./index";

import * as routesApi from "../constants/api"

export function profileGet() {
    return fetching("PROFILE_GET", routesApi.profile.get);
}

export function profileUpdate(data) {
    return fetching("PROFILE_UPDATE", routesApi.profile.update, {
        form: {
            ... data
        }
    });
}