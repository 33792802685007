import React from "react";
import {connect} from "react-redux"

import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop/Backdrop";

class Cancel extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <Backdrop style={{
            zIndex: 1000,
        }} open={!!this.props.showLoad}><CircularProgress color={"secondary"} size={27} /></Backdrop>
    }
}

const mapStateToProps = (state) => {
    return {
        showLoad: state.coreReducer.showLoad,
    }
};


const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default (connect(mapStateToProps, mapDispatchToProps)(Cancel))
