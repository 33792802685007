import React from "react";
import {connect} from "react-redux"

import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import Typography from "@material-ui/core/Typography";

class Offline extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <Backdrop open={true}>
            <Typography variant={"subtitle1"} align={"center"} style={{color: "#ffffff"}}>Нет подключения <br /> к интернету</Typography>
        </Backdrop>
    }
}

const mapStateToProps = (state) => {
    return {}
};


const mapDispatchToProps = (dispatch) => {
    return {}
};

export default (connect(mapStateToProps, mapDispatchToProps)(Offline))
