import ReactDOM from "react-dom"
import React from "react"
import { Provider } from "react-redux"
import { createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"
import promiseMiddleware from "redux-promise-middleware";
import {SnackbarProvider} from "notistack";

import Routing from "./routing"
import Reducer from "./reducer"
import { init } from "@sentry/browser";

import _ from "lodash";

if (!!window.sentryFrontDsn) {
    init({
        dsn: window.sentryFrontDsn,
    });
}

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/assets/service-worker.js').then(registration => {
            console.log('SW registered: ', registration);
        }).catch(registrationError => {
            console.log('SW registration failed: ', registrationError);
        });
    });
}

const store = createStore(Reducer, compose(
    applyMiddleware(thunk, promiseMiddleware)
));

import "./styles.scss"

window.appVersion = 'v0.1.17'

ReactDOM.render(
    <Provider store={store}>
        <SnackbarProvider maxSnack={3} anchorOrigin={{vertical: "top", horizontal: "center"}} autoHideDuration={2500}>
            <Routing />
        </SnackbarProvider>
    </Provider>,
    document.getElementById("app")
);

if (module.hot) {
    module.hot.accept()
}
