// alert

export function addAlert(message, view = null) {
    return {
        type: "ADD_ALERT",
        message, view
    };
}

// link

export function setLink(link) {
    return {
        type: "SET_LINK",
        link
    };
}

export function setShowLoad(showLoad) {
    return {
        type: "SET_SHOW_LOAD",
        showLoad
    };
}

export function setCancelBooking(cancelBooking) {
    return {
        type: "SET_CANCEL_BOOKING",
        cancelBooking
    };
}

export function setLockBookings(lockBookings) {
    return {
        type: "SET_LOCK_BOOKINGS",
        lockBookings
    };
}

export function setShowReplenishBalance(showReplenishBalance) {
    return {
        type: "SET_SHOW_REPLENISH_ACCOUNT",
        showReplenishBalance
    };
}

export function setShowRedirectProfile(showRedirectProfile) {
    return {
        type: "SET_SHOW_REDIRECT_PROFILE",
        showRedirectProfile
    };
}

export function setShowLogout(showLogout) {
    return {
        type: "SET_SHOW_LOGOUT",
        showLogout
    };
}

export function clearCurrent(clearCurrent) {
    return {
        type: "SET_CLEAR_CURRENT",
        clearCurrent
    };
}

