import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import * as actionsAuth from "../../actions/auth";
import * as actionsCore from "../../actions/core";

class LogoutPage extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.setShowLoad(true);
        this.props.authLogout().then(() => {

        }).catch(() => {
            this.props.addAlert("Что-то пошло не так");
        }).finally(() => {
            this.props.setShowLoad(false);
        });
    }

    render() {
        return null;
    }
}


const mapStateToProps = (state) => {
    return {
        load: state.authReducer.load,
        auth: state.authReducer.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        authLogout: bindActionCreators(actionsAuth.authLogout, dispatch),

        addAlert: bindActionCreators(actionsCore.addAlert, dispatch),
        setLink: bindActionCreators(actionsCore.setLink, dispatch),
        setShowLoad: bindActionCreators(actionsCore.setShowLoad, dispatch),
    }
};

export default (connect(mapStateToProps, mapDispatchToProps)(LogoutPage))
