export const auth = {
    exit: "/auth/exit",
    login: "/auth/login",
};

export const calendar = {
    calendar: "/calendar",
};

export const booking = {
    list: "/booking",
};

export const balances = {
    list: "/balances",
};

export const setting = {
    setting: "/setting",
    history: "/setting/history",
    profile: "/setting/profile",
};