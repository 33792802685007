import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux"
import {bindActionCreators} from "redux"

import {
    Router,
    Route,
    Redirect,
    Switch
} from "react-router-dom";

import { withSnackbar } from "notistack";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import PaymentIcon from "@material-ui/icons/Payment";
import SettingsIcon from "@material-ui/icons/Settings";

import createMuiTheme from "@material-ui/core/styles/createTheme";
import {ThemeProvider} from "@material-ui/styles"

const createHistory = require("history").createBrowserHistory;

import LogoutPage from "../pages/LogoutPage"
import CalendarPage from "../pages/CalendarPage"
import BookingPage from "../pages/BookingPage"
import BalancePage from "../pages/BalancePage"
import SettingPage from "../pages/SettingPage"
import SettingHistoryPage from "../pages/SettingHistoryPage"
import SettingProfilePage from "../pages/SettingProfilePage"
import LoginPage from "../pages/LoginPage"
import NotFoundPage from "../pages/NotFoundPage"

import CancelBooking from "../components/CancelBooking"
import LockBookings from "../components/LockBookings"
import ShowReplenishBalance from "../components/ShowReplenishBalance"
import ShowRedirectProfile from "../components/ShowRedirectProfile"
import ShowLogout from "../components/ShowLogout"
import Load from "../components/Load"
import Offline from "../components/Offline"

import * as actionsAuth from "../actions/auth"
import * as actionsLocation from "../actions/location"
import * as actionsVersion from "../actions/version"

import * as routesPage from "../constants/page"
import * as actionsCore from "../actions/core"

import "./style.scss"

const themeMobile = createMuiTheme({
    palette: {
        primary: {
            main: "#2f474a",
            contrastText: "#ffffff",
        },
        secondary: {
            main: "#8da99d",
            contrastText: "#ffffff",
        },
    },
    typography: {
        fontFamily: "Rubik Medium, Roboto, Helvetica Neue, Arial, sans-serif"
    },
    overrides: {
        MuiDrawer: {
            paperAnchorBottom: {
                left: 16,
                right: 16,
            }
        },
        MuiTextField: {
            root: {

            },
        },
        MuiPaper: {
            root: {

            }
        },
        MuiButton: {
            root: {
                borderRadius: 10
            }
        },
        MuiBackdrop: {
            root: {
                // zIndex: 1000
            }
        },
        MuiCardContent: {
            root: {
                backgroundColor: "#f5f6fa"
            }
        },

        MuiCardActions: {
            root: {
                backgroundColor: "#f5f6fa"
            }
        },
        MuiCardHeader: {
            root: {
                borderRadius: "16px 16px 0px 0px",
                backgroundColor: "#2f474a",
            },
            title: {
                fontWeight: 100,
                color: "#f5f5f0"
            },
            subheader: {
                fontWeight: 100,
                color: "#f5f5f0"
            }
        }
    }
});

const mapStateToProps = (state) => {
    return {
        loadAuth: state.authReducer.loadAuth,
        auth: state.authReducer.auth,

        alert: state.coreReducer.alert,
        link: state.coreReducer.link,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        authStatus: bindActionCreators(actionsAuth.authStatus, dispatch),
        setLink: bindActionCreators(actionsCore.setLink, dispatch),

        versionGet: bindActionCreators(actionsVersion.versionGetAction, dispatch),

        setAvailableSuccess: bindActionCreators(actionsLocation.setAvailableSuccess, dispatch),
        setAvailableReject: bindActionCreators(actionsLocation.setAvailableReject, dispatch),
    }
};

class PrivateRoute extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return this.props.loadAuth
            ? this.props.auth
                ? <Route
                    exact={this.props.exact}
                    path={this.props.path}
                    component={this.props.component}
                />
                : <Redirect to={{
                    pathname: routesPage.auth.login
                }} />
            : null
    };
}

PrivateRoute.propTypes = {
    exact: PropTypes.bool,
    path: PropTypes.string,
    component: PropTypes.func
};

PrivateRoute.defaultProps = {
    exact: false
};

PrivateRoute = (connect(mapStateToProps, mapDispatchToProps)(PrivateRoute));

class PublicRoute extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        let defaultRedirect = routesPage.calendar.calendar
        if (localStorage && this.props.loadAuth && this.props.auth) {
            let redirectAfterLogin = localStorage.getItem("redirectAfterLogin");
            if (redirectAfterLogin) {
                localStorage.removeItem("redirectAfterLogin");
                defaultRedirect = redirectAfterLogin;
            }
        }

        return this.props.loadAuth
            ? this.props.auth
                ? <Redirect to={{
                    pathname: defaultRedirect
                }} />
                : <Route
                    exact={this.props.exact}
                    path={this.props.path}
                    component={this.props.component}
                />
            : null
    };
}

PublicRoute.propTypes = {
    exact: PropTypes.bool,
    path: PropTypes.string,
    component: PropTypes.func
};

PublicRoute.defaultProps = {
    exact: false
};

PublicRoute = (connect(mapStateToProps, mapDispatchToProps)(PublicRoute));

class Routing extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selected: 0,
            online: navigator.onLine,
            openMenu: false
        };

        this.history = createHistory();
    }

    handleTab(link) {
        let selected = 0;

        if (!link) {
            link = this.props.link;
        }

        if (link === routesPage.calendar.calendar) {
            selected = 0;
        // } else if (link === routesPage.booking.list) {
        //     selected = 1;
        } else if (link === routesPage.balances.list) {
            selected = 1;
        } else if (
            link === routesPage.setting.setting ||
            link === routesPage.setting.history ||
            link === routesPage.setting.profile ||
            link === routesPage.auth.login ||
            link === routesPage.auth.code
        ) {
            selected = 2;
        }

        this.setState({
            selected: selected
        });
    }

    componentDidMount() {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.props.setAvailableSuccess(position.coords.latitude, position.coords.longitude)
            }, () => {
                this.props.setAvailableReject();
            });
        } else {
            this.props.setAvailableReject();
        }

        setInterval(() => {
            // console.log(3)
            this.props.versionGet().then(response => {
                if (window.ciCommitRefName !== response.value) {
                    window.location = window.location.pathname + window.location.search;
                }
            }).catch(() => {
                // window.location = window.location.pathname + window.location.search;
            });
        }, 2500);

        this.handleLoad();
        this.handleTab();

        this.isMount = true;

        let self = this;

        window.addEventListener("load", () => {
            window.addEventListener("online", () => {
                self.setState({
                    online: navigator.onLine
                });
            });
            window.addEventListener("offline", () => {
                self.setState({
                    online: navigator.onLine
                });
            });
        });
    }

    handleLoad() {
        this.props.authStatus();
    }

    componentDidUpdate(prevProps) {

        if (prevProps.link !== this.props.link && !_.isNull(this.props.link)) {
            this.history.push(this.props.link);
            this.handleTab(this.props.link);
        }

        if (prevProps.alert !== this.props.alert) {
            this.props.enqueueSnackbar(this.props.alert.message)
        }

        if (prevProps.loadAuth !== this.props.loadAuth) {
            this.handleLoad();
        }
    }

    handleChange(selected) {

        this.setState({
            selected
        });

        if (selected === 0) {
            this.props.setLink(routesPage.calendar.calendar);
        } else if (selected === 1) {
            // this.props.setLink(routesPage.booking.list);
        // } else if (selected === 2) {
            this.props.setLink(routesPage.balances.list);
        } else if (selected === 2 && this.props.auth) {
            this.props.setLink(routesPage.setting.setting);
        } else if (selected === 2 && !this.props.auth) {
            this.props.setLink(routesPage.auth.login);
        }
    }

//     handleAddToHomescreenClick = () => {
//         alert(`1. Open Share menu
// 2. Tap on "Add to Home Screen" button`);
//     };

    render() {
        return <Router history={this.history}>
            <ThemeProvider theme={themeMobile}>
                <div className={"work-area"}>
                    {/*<AddToHomeScreen onAddToHomescreenClick={this.handleAddToHomescreenClick} />*/}
                    <div className={"work-area__page"}>
                        { this.props.loadAuth
                            ? <Switch>
                                <Route
                                    exact={false}
                                    path={routesPage.calendar.calendar}
                                    render={(props) => {
                                        return <CalendarPage
                                            id={props.match.params.id ? props.match.params.id : null}
                                        />
                                    }}
                                />
                                <PrivateRoute
                                    exact={true}
                                    path={routesPage.booking.list}
                                    component={() => {
                                        return <BookingPage />
                                    }}
                                />
                                <PrivateRoute
                                    exact={true}
                                    path={routesPage.balances.list}
                                    component={() => {
                                        return <BalancePage />
                                    }}
                                />
                                <PrivateRoute
                                    exact={true}
                                    path={routesPage.setting.setting}
                                    component={() => {
                                        return <SettingPage />
                                    }}
                                />
                                <PrivateRoute
                                    exact={true}
                                    path={routesPage.setting.history}
                                    component={() => {
                                        return <SettingHistoryPage />
                                    }}
                                />
                                <PrivateRoute
                                    exact={true}
                                    path={routesPage.setting.profile}
                                    component={() => {
                                        return <SettingProfilePage />
                                    }}
                                />
                                <PrivateRoute
                                    exact={true}
                                    path={routesPage.auth.exit}
                                    component={() => {
                                        return <LogoutPage />
                                    }}
                                />
                                <PublicRoute
                                    exact={true}
                                    path={routesPage.auth.login}
                                    component={() => {
                                        return <LoginPage />
                                    }}
                                />
                                <Route component={() => {
                                    return <NotFoundPage />
                                }}/>
                            </Switch>
                            : null
                        }
                    </div>

                    <Tabs
                        value={this.state.selected}
                        onChange={(e, v) => this.handleChange(v)}
                        variant="fullWidth"
                        indicatorColor="primary"
                        textColor="primary"
                        className={"work-area__tabs" + (this.props.loadAuth ? "" : " loadAuth")}
                    >
                        <Tab icon={<EventAvailableIcon />} />
                        {/*<Tab className={!this.props.auth ? "work-area__tabs--hide" : null} icon={<EventAvailableIcon />} disabled={!this.props.auth} />*/}
                        <Tab className={!this.props.auth ? "work-area__tabs--hide" : null} icon={<PaymentIcon />} disabled={!this.props.auth} />
                        <Tab icon={<SettingsIcon />} />
                    </Tabs>

                    <CancelBooking />
                    <LockBookings />
                    <ShowRedirectProfile />
                    <ShowReplenishBalance />
                    <ShowLogout />

                    <Load />
                    
                    { !this.state.online
                        ? <Offline />
                        : null
                    }
                </div>
            </ThemeProvider>
        </Router>
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Routing)))
