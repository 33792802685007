import React from "react";
import {connect} from "react-redux"
import {bindActionCreators} from "redux"
import moment from "moment";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Drawer from "@material-ui/core/Drawer";

import * as actionsCore from "../../actions/core";
import * as actionsBalance from "../../actions/balances";
import * as actionsPayments from "../../actions/payment";

import "./style.scss"

class BalancePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            last: null,

            next: 0,
            items: [],

            error: "",
            value: "",

            showDraft: null
        };

        this.isMount = true;
    }

    componentDidMount() {
        this.props.setShowLoad(true);
        this.props.balancesLast().then(response => {
            if (this.isMount) {
                this.setState({
                    last: response.value,
                })
            }

            this.props.balancesList(0).then(response => {
                if (this.isMount) {
                    this.setState({
                        next: response.value.next,
                        items: response.value.items,
                    })
                }
            }).catch(() => {
                this.props.addAlert("Что-то пошло не так");
            }).finally(() => {
                this.props.setShowLoad(false);
            });

        }).catch(() => {
            this.props.addAlert("Что-то пошло не так");
        });
    }

    moreHandle = () => {
        this.props.setShowLoad(true);
        this.props.balancesList(this.state.next).then(response => {
            if (this.isMount) {
                this.setState({
                    next: response.value.next,
                    items: _.concat(this.state.items, response.value.items),
                })
            }
        }).catch(() => {
            this.props.addAlert("Что-то пошло не так");
        }).finally(() => {
            this.props.setShowLoad(false);
        });
    };

    updateValue = (value) => {
        value = value.replace(/[^0-9]/g, "");

        this.setState({
            value: value,
            error: null
        });
    };

    paymentHandle = () => {
        this.props.setShowLoad(true);
        this.props.paymentDraft(parseFloat(this.state.value)).then(response => {
            if (this.isMount) {
                this.setState({
                    value: "",
                    showDraft: {
                        amount: response.value.amount,
                        id: response.value.id
                    }
                });
            }
        }).catch(error => {
            if (this.isMount) {
                this.setState({
                    error: error.error.data.amount.join(<br />),
                });
            }
        }).finally(() => {
            this.props.setShowLoad(false);
        });
    };

    render() {
        return (
            <div className={"balances-page"}>
                <Typography className={"base-h4"} variant={"h4"}>Баланс</Typography>
                <div className={"balances-page__items"} key={"items"}>
                    <Card className={"balances-page__items--item"}>
                        <CardHeader
                            className={"balances-page--current"}
                            title={"Баланс: " + (this.state.last ? this.state.last.balance : 0) + "₽"}
                        />
                        <CardHeader
                            className={"balances-page--current__any"}
                            title={"Скидка: " + (this.state.last ? this.state.last.sale : 0) + "%"}
                        />
                        <CardContent className={"accounts-page--input"}>
                            <TextField
                                fullWidth
                                type={"number"}
                                label="Сумма"
                                error={!!this.state.error}
                                helperText={this.state.error}
                                value={this.state.value}
                                onChange={(event) => {
                                    this.updateValue(event.target.value);
                                }}
                            />
                            <p>Комиссия сервиса 2,9%</p>
                        </CardContent>
                        <CardActions className={"accounts-page--action"}>
                            <Button variant={"contained"} color={"primary"} onClick={() => {
                                this.paymentHandle();
                            }}>Пополнить</Button>
                        </CardActions>
                    </Card>
                    { this.state.items && this.state.items.length
                        ? <Typography className={"base-h4"} variant={"h4"}>История</Typography>
                        : null
                    }
                    { this.state.items.map(item => {
                        return <Card key={item.id} className={"balances-page__items--item"}>
                            <CardContent>
                                <Typography gutterBottom>
                                    { item.sum < 0
                                        ? "Покупка"
                                        : "Возврат/пополнение"
                                    } от { moment(item.createdAt).format("DD.MM.YYYY HH:mm:ss") }
                                </Typography>
                                <Typography variant={"h5"}>
                                    { item.sum } ₽
                                </Typography>
                            </CardContent>
                        </Card>
                    }) }
                </div>
                {  this.state.next
                    ? <div className={"balances-page__more"}>
                        <Button color={"secondary"} size={"small"} variant={"contained"} onClick={() => {
                            this.moreHandle();
                        }}>Еще</Button>
                    </div>
                    : null
                }
                <Drawer anchor="bottom" open={!!this.state.showDraft} onClose={() => {
                    this.setState({
                        showDraft: null
                    })
                }}>
                    <Card>
                        <CardContent>
                            <Typography variant={"h6"} align={"left"}>Оплата</Typography>
                            { this.state.showDraft
                                ? <Typography variant={"subtitle2"} align={"left"}>Продолжить отплату на сумму { new Intl.NumberFormat("ru-RU", { style: "currency", currency: "RUB", minimumFractionDigits: 0}).format(this.state.showDraft.amount) }</Typography>
                                : null
                            }
                        </CardContent>
                        <CardActions>
                            <Button variant={"contained"} size={"small"} color="secondary" autoFocus onClick={() => {
                                window.open("/api/cabinet/payment/process/" + this.state.showDraft.id + ".json");
                                this.setState({
                                    showDraft: null
                                })
                            }}>
                                Оплатить
                            </Button>
                            <Button onClick={() => {
                                this.setState({
                                    showDraft: null
                                })
                            }} variant={"contained"} size={"small"} color="default">Закрыть</Button>

                        </CardActions>
                    </Card>
                </Drawer>
            </div>
        );
    }

    componentWillUnmount() {
        this.isMount = false;
    }
}

const mapStateToProps = () => {
    return {
    }
};


const mapDispatchToProps = (dispatch) => {
    return {
        addAlert: bindActionCreators(actionsCore.addAlert, dispatch),
        setLink: bindActionCreators(actionsCore.setLink, dispatch),
        setShowLoad: bindActionCreators(actionsCore.setShowLoad, dispatch),

        paymentDraft: bindActionCreators(actionsPayments.paymentDraft, dispatch),
        balancesLast: bindActionCreators(actionsBalance.balancesLast, dispatch),
        balancesList: bindActionCreators(actionsBalance.balancesList, dispatch),
    }
};

export default (connect(mapStateToProps, mapDispatchToProps)(BalancePage))
