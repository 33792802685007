export const auth = {
    login: "/api/cabinet/auth/login.json",
    status: "/api/cabinet/auth/status.json",
    exit: "POST:/api/cabinet/auth/exit.json",
};

export const profile = {
    get: "/api/cabinet/profile/get.json",
    update: "/api/cabinet/profile/update.json",
};

export const cabinet = {
    list: "/api/cabinet/cabinet/list.json",
};

export const calendar = {
    list: "/api/cabinet/calendar/list.json?officeId=:officeId&date=:date",
};

export const booking = {
    list: "/api/cabinet/booking/list.json?skip=:skip",
    history: "/api/cabinet/booking/history.json?skip=:skip",
    lock: "/api/cabinet/booking/lock.json",
    cancel: "POST:/api/cabinet/booking/cancel.json?id=:id",
};

export const balance = {
    list: "/api/cabinet/balance/list.json?skip=:skip",
    last: "/api/cabinet/balance/last.json"
};

export const payment = {
    draft: "/api/cabinet/payment/draft.json"
};

export const version = {
    version: "/version.json",
};