import React from "react";
import {connect} from "react-redux"
import {bindActionCreators} from "redux"

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import moment from "moment";

import * as actionsCore from "../../actions/core";
import * as actionsBooking from "../../actions/booking";

import "./style.scss"

class SettingHistoryPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            next: 0,
            items: []
        };

        this.isMount = true;
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    componentDidMount() {
        this.props.setShowLoad(true);
        this.props.bookingHistory(0).then(response => {
            if (this.isMount) {
                this.setState({
                    next: response.value.next,
                    items: response.value.items,
                })
            }
        }).catch(() => {
            this.props.addAlert("Что-то пошло не так");
        }).finally(() => {
            this.props.setShowLoad(false);
        });
    }

    moreHandle = () => {
        this.props.setShowLoad(true);
        this.props.bookingHistory(this.state.next).then(response => {
            if (this.isMount) {
                this.setState({
                    next: response.value.next,
                    items: _.concat(this.state.items, response.value.items),
                })
            }
        }).catch(() => {
            this.props.addAlert("Что-то пошло не так");
        }).finally(() => {
            this.props.setShowLoad(false);
        });
    };

    render() {
        return (
            <div className={"setting-history-page"}>
                <Typography className={"base-h4"} variant={"h4"}>История бронирований</Typography>
                <div className={"setting-history-page__items"}>
                    { this.state.items.map(item => {
                        return <Card key={item.id} className={"setting-history-page__items--item"}>
                            <CardContent>
                                <Typography variant="body2">
                                    Бронирование №{ item.id } от { moment(item.createdAt).format("DD.MM.YYYY HH:mm") }
                                </Typography>
                                { item.cabinet && item.cabinet.office
                                    ? <Typography variant="body2">
                                        Офис: { item.cabinet.office.name }
                                    </Typography>
                                    : null
                                }
                                { item.cabinet
                                    ? <Typography variant="body2">
                                        Кабинет: { item.cabinet.name }
                                    </Typography>
                                    : null
                                }
                                <Typography >
                                    { moment(item.date).format("DD.MM.YYYY") + " c " + (item.hour + "").padStart(2, "0") + (item.minute === "1/2" ? ":00" : ":30") + " по " + ((item.hour + (item.minute === "2/2" ? 1 : 0)) + "").padStart(2, "0") + (item.minute === "1/2" ? ":30" : ":00") }
                                </Typography>
                                <Typography variant={"h6"}>
                                    { item.sum } ₽
                                </Typography>
                            </CardContent>
                        </Card>
                    }) }
                    { this.state.next
                        ? <div className={"setting-history-page__more"}>
                            <Button color={"secondary"} variant={"contained"} onClick={() => {
                                this.moreHandle();
                            }}>Еще</Button>
                        </div>
                        : null
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        addAlert: bindActionCreators(actionsCore.addAlert, dispatch),
        setLink: bindActionCreators(actionsCore.setLink, dispatch),
        setShowLoad: bindActionCreators(actionsCore.setShowLoad, dispatch),

        bookingHistory: bindActionCreators(actionsBooking.bookingHistory, dispatch)
    }
};

export default (connect(mapStateToProps, mapDispatchToProps)(SettingHistoryPage))
