import React from "react";
import {connect} from "react-redux"
import {bindActionCreators} from "redux"
import EventDispatcher from "react-event-dispatcher";

import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Card from "@material-ui/core/Card";

import * as actionsBooking from "../../actions/booking";
import * as actionsCore from "../../actions/core";

class CancelBooking extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            lockDrawer: false
        };
    }

    render() {
        return <Drawer  style={{
            zIndex: this.state.lockDrawer ? 500 : "initial"
        }} anchor="bottom" open={!!this.props.cancelBooking} onClose={() => {
            this.props.setCancelBooking(null);
        }}>
            <Card>
                <CardHeader title={"Точно хотите отменить?"} />
                { this.props.cancelBooking && !this.props.cancelBooking.canFullReturn
                    ? <CardContent>
                        { this.props.cancelBooking && !this.props.cancelBooking.canCancelOwner
                            ? <Typography variant={"subtitle1"} align={"left"}>
                                Данное бронирование сделано администратором, для его отмены пожалуйста обратитесь к администратору
                            </Typography>
                            : null
                        }
                        <Typography variant={"subtitle1"} align={"left"}>
                            При отмене брони менее, чем за {this.props.cancelBooking.cancelTime} часов 50% от суммы удерживается сервисом.
                        </Typography>
                    </CardContent>
                    : null
                }
                <CardActions>
                    { this.props.cancelBooking && this.props.cancelBooking.canCancelOwner
                        ? <Button variant={"contained"} color="primary" onClick={() => {
                            this.props.setShowLoad(true);
                            this.setState({
                                lockDrawer: true
                            });

                            this.props.bookingCancel(this.props.cancelBooking.id).then(response => {
                                this.props.addAlert("Бронирование отменено");

                                EventDispatcher.triggerOne("CancelBooking", 0, this.props.cancelBooking.id);

                            }).catch(error => {
                                this.props.addAlert("Что-то пошло не так");
                            }).finally(() => {
                                this.props.setShowLoad(false);
                                this.setState({
                                    lockDrawer: false
                                });
                                this.props.setCancelBooking(null);
                            })
                        }}>
                            Отменить
                        </Button>
                        : null
                    }
                    <Button onClick={() => {
                        this.props.setCancelBooking(null);
                    }} variant={"contained"} color="secondary">Закрыть</Button>
                </CardActions>
            </Card>
        </Drawer>
    }

    componentWillUnmount() {
        this.isMount = false;
    }
}

const mapStateToProps = (state) => {
    return {
        cancelBooking: state.coreReducer.cancelBooking,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        bookingCancel: bindActionCreators(actionsBooking.bookingCancel, dispatch),

        setCancelBooking: bindActionCreators(actionsCore.setCancelBooking, dispatch),
        addAlert: bindActionCreators(actionsCore.addAlert, dispatch),
        setShowLoad: bindActionCreators(actionsCore.setShowLoad, dispatch),
    }
};

export default (connect(mapStateToProps, mapDispatchToProps)(CancelBooking))
