import React from "react";
import {connect} from "react-redux"
import {bindActionCreators} from "redux"

import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import CardActions from "@material-ui/core/CardActions";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import * as routesPage from "../../constants/page"

import * as actionsCore from "../../actions/core";

class ShowRedirectProfile extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {
        return <Drawer anchor="bottom" open={!!this.props.showRedirectProfile} onClose={() => {
            this.props.setShowRedirectProfile(null);
        }}>
            <Card>
                <CardContent>
                    <Typography variant={"h6"} align={"left"}>Необходимо заполнить профиль</Typography>
                    <Typography variant={"subtitle2"} align={"left"}>Необходимо заполнить профиль для посещения коворкинга</Typography>
                </CardContent>
                <CardActions>
                    <Button onClick={() => {
                        this.props.setShowRedirectProfile(null);
                        this.props.setLink(routesPage.setting.profile);
                    }} variant={"contained"} color="primary">Заполнить</Button>
                    <Button onClick={() => {
                        this.props.setShowRedirectProfile(null);
                    }} variant={"contained"} color="secondary">Закрыть</Button>
                </CardActions>
            </Card>
        </Drawer>
    }

    componentWillUnmount() {
        this.isMount = false;
    }
}

const mapStateToProps = (state) => {
    return {
        showRedirectProfile: state.coreReducer.showRedirectProfile,
    }
};


const mapDispatchToProps = (dispatch) => {
    return {
        setShowRedirectProfile: bindActionCreators(actionsCore.setShowRedirectProfile, dispatch),
        setLink: bindActionCreators(actionsCore.setLink, dispatch),
    }
};

export default (connect(mapStateToProps, mapDispatchToProps)(ShowRedirectProfile))
