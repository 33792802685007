import { combineReducers } from "redux"

import coreReducer from "./core"
import authReducer from "./auth"

const Reducer = combineReducers({
    coreReducer,
    authReducer,
});

export default Reducer
