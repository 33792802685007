import React from "react";
import {connect} from "react-redux"
import {bindActionCreators} from "redux"
import moment from "moment";
import EventDispatcher from "react-event-dispatcher";

import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Card from "@material-ui/core/Card";

import * as routesPage from "../../constants/page"
import * as actionsCore from "../../actions/core";
import * as actionsBooking from "../../actions/booking";

class LockBookings extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            lockDrawer: false
        };
    }

    render() {
        return <Drawer  style={{
            zIndex: this.state.lockDrawer ? 500 : "initial"
        }} anchor="bottom" open={!!this.props.lockBookings} onClose={() => {
            this.props.setLockBookings(null);
        }}>
            <Card>
                <CardHeader title={this.props.lockBookings ? "Забронировать слот" + (this.props.lockBookings.length > 1 ? "ы" : "" ) + "?" : ""} />
                <CardContent>
                    <Typography variant={"subtitle1"} align={"left"}>
                        { this.props.lockBookings && this.props.lockBookings.length > 0
                            ? "Офис: " + this.props.lockBookings[0].cabinet.office.name + ", " + moment(this.props.lockBookings[0].date).format("DD.MM.YYYY")
                            : null
                        }
                    </Typography>
                    { this.props.lockBookings
                        ? this.props.lockBookings.map((lockBooking, index) => {
                            return <div key={index}>
                                <Typography variant={"subtitle1"} align={"left"}>
                                    { "Кабинет: " + lockBooking.cabinet.name + " c " + (lockBooking.hour + "").padStart(2, "0") + (lockBooking.minute === "1/2" ? ":00" : ":30") + " по " + ((lockBooking.hour + (lockBooking.minute === "2/2" ? 1 : 0)) + "").padStart(2, "0") + (lockBooking.minute === "1/2" ? ":30" : ":00") }
                                </Typography>
                                <Typography variant={"subtitle1"} align={"left"}>
                                    Цена: { lockBooking.price.price } ₽, отмена за: { lockBooking.price.cancelTime } час.
                                </Typography>
                            </div>
                        })
                        : null
                    }
                </CardContent>
                <CardActions>
                    { this.props.lockBookings
                        ? <Button variant={"contained"} color="primary" onClick={() => {
                            this.props.setShowLoad(true);
                            this.setState({
                                lockDrawer: true
                            });

                            { this.props.lockBookings.map(lockBooking => {
                                this.props.bookingLock(lockBooking.cabinet.id, lockBooking.date, lockBooking.hour, lockBooking.minute).then(response => {
                                    this.props.addAlert("Бронирование добавлено");
                                    EventDispatcher.triggerOne("LockBooking", 0, response.value);
                                }).catch(error => {
                                    if (error.error.code === 401) {
                                        this.props.setLink(routesPage.auth.login);
                                        this.props.addAlert("Для бронирования необходима авторизация", "warning");
                                    } else if (error.error.message.indexOf("Недостаточно средств.") !== -1) {
                                        this.props.setShowReplenishAccount(true);
                                    } else {
                                        this.props.addAlert("Что-то пошло не так");
                                    }
                                }).finally(() => {
                                    this.props.setShowLoad(false);
                                    this.setState({
                                        lockDrawer: false
                                    });
                                    this.props.setLockBookings(null);
                                })
                            }) }
                        }}>
                            Забронировать
                        </Button>
                        : null
                    }
                    <Button onClick={() => {
                        this.props.setLockBookings(null);
                    }} variant={"contained"} color="secondary">Закрыть</Button>
                </CardActions>
            </Card>
        </Drawer>
    }

    componentWillUnmount() {
        this.isMount = false;
    }
}

const mapStateToProps = (state) => {
    return {
        lockBookings: state.coreReducer.lockBookings,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        bookingLock: bindActionCreators(actionsBooking.bookingLock, dispatch),

        setShowReplenishAccount: bindActionCreators(actionsCore.setShowReplenishBalance, dispatch),
        setLink: bindActionCreators(actionsCore.setLink, dispatch),

        setLockBookings: bindActionCreators(actionsCore.setLockBookings, dispatch),
        addAlert: bindActionCreators(actionsCore.addAlert, dispatch),
        setShowLoad: bindActionCreators(actionsCore.setShowLoad, dispatch),
    }
};

export default (connect(mapStateToProps, mapDispatchToProps)(LockBookings))
