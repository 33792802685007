import {fetching} from "./index";

import * as routesApi from "../constants/api"

export function paymentDraft(amount) {
    return fetching("PAYMENTS_DRAFT", routesApi.payment.draft, {
        form: {
            amount
        }
    });
}