let defaultState = {
    loadAuth: false,
    auth: false,
    userInfo: null,
};

const AuthReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "BOOKING_CANCEL_FULFILLED":
        case "BOOKING_TIME_LINE_FULFILLED":
        case "CALENDAR_LIST_FULFILLED": {
            let {userInfo} = action.payload;

            return {
                ...state,
                userInfo,
            };
        }
        case "AUTH_LOGIN_FULFILLED": {
            let {result} = action.payload;
            return {
                ...state,
                loadAuth: false,
                auth: false
            };
        }
        case "AUTH_STATUS_FULFILLED": {
            return {
                ...state,
                loadAuth: true,
                auth: action.payload
            };
        }
        case "AUTH_EXIT_FULFILLED": {
            return {
                ...state,
                loadAuth: false,
                auth: false
            };
        }
        default:
            return state;
    }
};

export default AuthReducer;
