import React from "react";
import {connect} from "react-redux"
import {bindActionCreators} from "redux"

import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import CardActions from "@material-ui/core/CardActions";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import * as routesPage from "../../constants/page"

import * as actionsCore from "../../actions/core";

class ShowLogout extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {
        return <Drawer anchor="bottom" open={!!this.props.showLogout} onClose={() => {
            this.props.setShowLogout(null);
        }}>
            <Card>
                <CardContent>
                    <Typography variant={"h6"} align={"left"}>Выход из профиля</Typography>
                    <Typography variant={"subtitle2"} align={"left"}>Вы действительно хотите выйти из профиля?</Typography>
                </CardContent>
                <CardActions>
                    <Button onClick={() => {
                        this.props.setShowLogout(null);
                        this.props.setLink(routesPage.auth.exit);
                    }} variant={"contained"} color="primary">Да</Button>
                    <Button onClick={() => {
                        this.props.setShowLogout(null);
                    }} variant={"contained"} color="secondary">Закрыть</Button>
                    <span style={{
                        position: "absolute",
                        right: 8,
                        bottom: 8,
                        color: "gray",
                        fontSize: "small"
                    }}>{ window.ciCommitRefName }</span>
                </CardActions>
            </Card>
        </Drawer>
    }

    componentWillUnmount() {
        this.isMount = false;
    }
}

const mapStateToProps = (state) => {
    return {
        showLogout: state.coreReducer.showLogout,
    }
};


const mapDispatchToProps = (dispatch) => {
    return {
        setShowLogout: bindActionCreators(actionsCore.setShowLogout, dispatch),
        setLink: bindActionCreators(actionsCore.setLink, dispatch),
    }
};

export default (connect(mapStateToProps, mapDispatchToProps)(ShowLogout))
