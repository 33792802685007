import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Cookies from "js-cookie";

import {
    Redirect
} from "react-router-dom"

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";

import * as actionsAuth from "../../actions/auth";
import * as actionsCore from "../../actions/core";

import "./style.scss"

class LoginPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            agreement: false,
            focus: true,

            values: {
                email: "",
                password: "",
            },

            errors: {
                email: "",
                password: "",
            },
        };
    }

    componentDidMount() {
        let email = this.getFromCookie("last_email");

        if (typeof email === "string" && email.length > 0) {
            let values = this.state.values;

            values.email = email;

            this.setState({
                values: values
            });
        }
    }

    updateField = (field, value) => {
        let values = this.state.values;
        let errors = this.state.errors;
        values[field] = value;
        errors[field] = null;
        this.setState({
            values,
            errors
        })
    };

    saveInCookie = (field, value) => {
        Cookies.set(field, value, { expires: 365, path: "/" });
    };

    getFromCookie = (field) => {
        return Cookies.get(field);
    };

    emailHandle() {
        this.props.setShowLoad(true);

        if (this.state.values.email) {
            this.saveInCookie("last_email", this.state.values.email);
        }

        this.props.authLogin(this.state.values.email, this.state.values.password).then(response => {
            this.setState({
                redirect: "/auth/code/" + response.value
            });
        }).catch(error => {
            this.setState({
                errors: error.error.data,
            });
        }).finally(() => {
            this.props.setShowLoad(false);
        });
    };

    render() {
        return (
            <div className={"login-page"} style={{
                backgroundImage: "url(/assets/images/bg.jpg)"
            }}>
                <Paper className={"login-page__paper"} style={{
                    backgroundColor: "#ffffff"
                }} elevation={3}>
                    <div className={"email-form"}>
                        { this.state.redirect ? <Redirect to={{
                            pathname: this.state.redirect
                        }} /> : null }
                        <Typography variant="h6" gutterBottom align={"left"}>
                            Войдите в личный кабинет
                        </Typography>
                        <br />
                        <FormGroup>
                            <TextField fullWidth label="E-mail" value={this.state.values.email} error={!!this.state.errors.email} helperText={this.state.errors.email} disabled={this.state.send} autoFocus onChange={(e) => {
                                this.updateField("email", e.target.value)
                            }} />
                        </FormGroup>
                        <br />
                        <FormGroup>
                            <TextField type={"password"} fullWidth label="Пароль" value={this.state.values.password} error={!!this.state.errors.password} helperText={this.state.errors.password} disabled={this.state.send} autoFocus onChange={(e) => {
                                this.updateField("password", e.target.value)
                            }} />
                        </FormGroup>
                        <br />
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch color={"secondary"} checked={this.state.agreement} onChange={() => {
                                    this.setState({
                                        agreement: !this.state.agreement
                                    })
                                }} />}
                                label={<Typography>Я принимаю условия <Link href={"https://theartofharmony.ru/rent"} target={"_blank"}>пользовательского соглашения</Link></Typography>}
                            />
                        </FormGroup>
                        <br />
                        <Button disabled={!this.state.agreement} variant={"contained"} color="secondary" size={"small"} fullWidth onClick={() => {
                            this.emailHandle();
                        }}>Далее</Button>
                    </div>
                </Paper>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        authLogin: bindActionCreators(actionsAuth.authLogin, dispatch),

        setLink: bindActionCreators(actionsCore.setLink, dispatch),
        addAlert: bindActionCreators(actionsCore.addAlert, dispatch),
        setShowLoad: bindActionCreators(actionsCore.setShowLoad, dispatch),
    }
};

export default (connect(mapStateToProps, mapDispatchToProps)(LoginPage));