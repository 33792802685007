import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Card from "@material-ui/core/Card";

import * as actionsProfile from "../../actions/profile"

import * as actionsCore from "../../actions/core";
import * as routesPage from "../../constants/page"

import "./style.scss"

class SettingProfilePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            focus: true,
            values: {
                password: "",
                phone: "",
                email: "",
                name: "",
            },
            errors: {
                password: "",
                phone: "",
                email: "",
                name: "",
            }
        };

        this.isMount = true;
    }

    componentDidMount() {
        this.props.setShowLoad(true);
        this.props.profileGet().then(response => {
            if (this.isMount) {
                this.setState({
                    values: response.value
                })
            }
        }).catch(() => {
            this.props.addAlert("Что-то пошло не так");
        }).finally(() => {
            this.props.setShowLoad(false);
        });
    }

    updateValue = (field, value) => {
        let errors = this.state.errors;
        let values = this.state.values;

        errors[field] = "";
        values[field] = value;

        this.setState({
            values: values,
            errors: errors
        });
    };

    updateHandle = () => {
        this.props.setShowLoad(true);
        this.props.profileUpdate({
            password: this.state.values.password,
            email: this.state.values.email,
            phone: this.state.values.phone,
            name: this.state.values.name,
        }).then(() => {
            if (localStorage) {
                let redirectAfterFillProfile = localStorage.getItem("redirectAfterFillProfile");
                if (redirectAfterFillProfile) {
                    localStorage.removeItem("redirectAfterFillProfile");
                    this.props.setLink(redirectAfterFillProfile);
                }
            }

            this.props.addAlert("Данные успешно обновлены");
        }).catch(error => {
            if (this.isMount) {
                this.setState({
                    errors: error.error.data,
                });
            }
            // this.props.addAlert("Что-то пошло не так");
        }).finally(() => {
            this.props.setShowLoad(false);
        });
    };

    render() {
        return (
            <div className={"profile-page"}>
                <Typography className={"base-h4"} variant={"h4"}>Профиль</Typography>
                { this.state.values
                    ? <Card className={"profile-page__items--item"}>
                        <CardHeader
                            className={"profile-page--current"}
                            title={ this.state.values.phone.substr(0, 2) + " " + this.state.values.phone.substr(2, 3) + " " + this.state.values.phone.substr(5, 3) + " " + this.state.values.phone.substr(8, 2)  + " " + this.state.values.phone.substr(10, 2) }
                        />
                        <CardContent className={"profile-page--input"}>
                            <TextField
                                fullWidth={true}
                                label="Имя"
                                error={!!this.state.errors.name}
                                helperText={this.state.errors.name}
                                value={this.state.values.name ? this.state.values.name : ""}
                                onChange={(event) => {
                                    this.updateValue("name", event.target.value);
                                }}
                            />
                            <br />
                            <br />
                            <TextField
                                fullWidth={true}
                                label="E-mail"
                                error={!!this.state.errors.email}
                                helperText={this.state.errors.email}
                                value={this.state.values.email}
                                onChange={(event) => {
                                    this.updateValue("email", event.target.value);
                                }}
                            />
                            <br />
                            <br />
                            <TextField
                                fullWidth={true}
                                label="Телефон"
                                error={!!this.state.errors.phone}
                                helperText={this.state.errors.phone}
                                value={this.state.values.phone}
                                onChange={(event) => {
                                    this.updateValue("phone", event.target.value);
                                }}
                            />
                            <br />
                            <br />
                            <TextField
                                fullWidth={true}
                                label="Пароль"
                                type={"password"}
                                error={!!this.state.errors.password}
                                helperText={this.state.errors.password}
                                value={this.state.values.password ? this.state.values.password : ""}
                                onChange={(event) => {
                                    this.updateValue("password", event.target.value);
                                }}
                            />
                        </CardContent>
                        <CardActions className={"profile-page--action"}>
                            <Button variant={"contained"} size={"small"} color={"secondary"} onClick={() => {
                                this.updateHandle();
                            }}>Сохранить</Button>
                        </CardActions>
                    </Card>
                    : null
                }
                <br />
            </div>
        );
    }

    componentWillUnmount() {
        this.isMount = false;
    }
}

const mapStateToProps = (state) => {
    return {
    }
};


const mapDispatchToProps = (dispatch) => {
    return {
        profileGet: bindActionCreators(actionsProfile.profileGet, dispatch),
        profileUpdate: bindActionCreators(actionsProfile.profileUpdate, dispatch),

        addAlert: bindActionCreators(actionsCore.addAlert, dispatch),
        setLink: bindActionCreators(actionsCore.setLink, dispatch),
        setShowLoad: bindActionCreators(actionsCore.setShowLoad, dispatch),
    }
};

export default (connect(mapStateToProps, mapDispatchToProps)(SettingProfilePage))