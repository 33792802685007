import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import * as actionsCore from "../../actions/core";
import * as routesPage from "../../constants/page"

class NotFoundPage extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.auth) {
            this.props.setLink(routesPage.calendar.calendar);
        } else {
            this.props.setLink(routesPage.auth.login);
        }
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        loadAuth: state.authReducer.loadAuth,
        auth: state.authReducer.auth,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setLink: bindActionCreators(actionsCore.setLink, dispatch),
    }
};

export default (connect(mapStateToProps, mapDispatchToProps)(NotFoundPage))
