import React from "react";
import {connect} from "react-redux"
import {bindActionCreators} from "redux"

import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import CardActions from "@material-ui/core/CardActions";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import * as actionsCore from "../../actions/core";

class ShowReplenishBalance extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {
        return <Drawer anchor="bottom" open={!!this.props.showReplenishBalance} onClose={() => {
            this.props.setShowReplenishBalance(null);
        }}>
            <Card>
                <CardContent>
                    <Typography variant={"h6"} align={"left"}>Недостаточно средств</Typography>
                    <Typography variant={"subtitle2"} align={"left"}>Необходимо пополнить баланс</Typography>
                </CardContent>
                <CardActions>
                    <Button onClick={() => {
                        this.props.setShowReplenishBalance(null);
                    }} variant={"contained"} color="secondary">Закрыть</Button>
                </CardActions>
            </Card>
        </Drawer>
    }

    componentWillUnmount() {
        this.isMount = false;
    }
}

const mapStateToProps = (state) => {
    return {
        showReplenishBalance: state.coreReducer.showReplenishBalance,
    }
};


const mapDispatchToProps = (dispatch) => {
    return {
        setShowReplenishBalance: bindActionCreators(actionsCore.setShowReplenishBalance, dispatch),
        setLink: bindActionCreators(actionsCore.setLink, dispatch),
    }
};

export default (connect(mapStateToProps, mapDispatchToProps)(ShowReplenishBalance))
