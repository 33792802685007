import {fetching} from "./index";

import * as routesApi from "../constants/api"

export function balancesList(skip) {
    return fetching("BALANCES_LIST", routesApi.balance.list.replace(":skip", skip));
}

export function balancesLast() {
    return fetching("BALANCES_LAST", routesApi.balance.last);
}