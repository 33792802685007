import {fetching} from "./index";

import * as routesApi from "../constants/api"

export function authLogin(email, password) {
    return fetching("AUTH_LOGIN", routesApi.auth.login, {
        form: {
            email,
            password
        }
    });
}

export function authStatus() {
    return fetching("AUTH_STATUS", routesApi.auth.status);
}

export function authLogout() {
    return fetching("AUTH_EXIT", routesApi.auth.exit);
}
