import {fetching} from "./index";

import * as routesApi from "../constants/api"

export function setAvailableSuccess(latitude, longitude) {
    return {
        type: "SET_AVAILABLE_SUCCESS",
        latitude,
        longitude,
    };
}

export function setAvailableReject() {
    return {
        type: "SET_AVAILABLE_REJECT",
    };
}