import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import EventDispatcher from "react-event-dispatcher";
import Cookies from "js-cookie";
import ClickNHold from "react-click-n-hold";

import DateFnsUtils from "@date-io/date-fns";
import {MuiPickersUtilsProvider, DatePicker} from "@material-ui/pickers";
import locale from 'date-fns/locale/ru'
locale.options.weekStartsOn = 1;
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {InputAdornment} from "@material-ui/core";
import {Event} from "@material-ui/icons";

import * as actionsCore from "../../actions/core";
import * as actionsCabinet from "../../actions/cabinet";
import * as actionsCalendar from "../../actions/calendar";

import "./style.scss";

class CalendarPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            date: null,
            officeId: "",
            calendar: null,
            cabinets: [],
            offices: [],
            selectSlots: [],
            stateHold: 0,
        };

        this.hoursWithMinutes = [{
            hour: 10,
            minute: "1/2",
        }, {
            hour: 10,
            minute: "2/2",
        }, {
            hour: 11,
            minute: "1/2",
        }, {
            hour: 11,
            minute: "2/2",
        }, {
            hour: 12,
            minute: "1/2",
        }, {
            hour: 12,
            minute: "2/2",
        }, {
            hour: 13,
            minute: "1/2",
        }, {
            hour: 13,
            minute: "2/2",
        }, {
            hour: 14,
            minute: "1/2",
        }, {
            hour: 14,
            minute: "2/2",
        }, {
            hour: 15,
            minute: "1/2",
        }, {
            hour: 15,
            minute: "2/2",
        }, {
            hour: 16,
            minute: "1/2",
        }, {
            hour: 16,
            minute: "2/2",
        }, {
            hour: 17,
            minute: "1/2",
        }, {
            hour: 17,
            minute: "2/2",
        }, {
            hour: 18,
            minute: "1/2",
        }, {
            hour: 18,
            minute: "2/2",
        }, {
            hour: 19,
            minute: "1/2",
        }, {
            hour: 19,
            minute: "2/2",
        }, {
            hour: 20,
            minute: "1/2",
        }, {
            hour: 20,
            minute: "2/2",
        }, {
            hour: 21,
            minute: "1/2",
        }, {
            hour: 21,
            minute: "2/2",
        }];

        this.isMount = true;
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    saveInCookie = (field, value) => {
        Cookies.set(field, value, { expires: 365, path: "/" });
    };

    getFromCookie = (field) => {
        return Cookies.get(field);
    };

    componentDidMount() {

        EventDispatcher.clear();

        EventDispatcher.dispatch("CancelBooking", (bookingId) => {
            if (!this.isMount) {
                return;
            }
            let bookings = this.state.calendar.bookings;
            if (!bookings) {
                return;
            }
            let bookingIndex = _.findIndex(bookings, {
                id: bookingId,
            });
            if (bookingIndex === -1) {
                return;
            }
            _.pullAt(bookings, [bookingIndex])
            this.setState({
                calendar: {
                    ...this.state.calendar,
                    bookings
                }
            });
        });

        EventDispatcher.dispatch("LockBooking", (booking) => {
            if (!this.isMount) {
                return;
            }

            let selectSlots = this.state.selectSlots;

            let slotIndex = _.findIndex(selectSlots, {
                cabinetId: booking.cabinetId,
                hour: booking.hour,
                minute: booking.minute,
            });

            if (slotIndex !== -1) {
                _.pullAt(selectSlots, [slotIndex])
            }

            let bookings = this.state.calendar.bookings;
            if (!bookings) {
                return;
            }
            bookings.push(booking);
            this.setState({
                selectSlots,
                calendar: {
                    ...this.state.calendar,
                    bookings
                }
            });
        });

        this.props.setShowLoad(true);

        this.props.listCabinetAction().then(response => {
            let offices = [];
            let cabinets = [];

            response.value.map(cabinet => {
                let office = _.find(offices, {
                    id: cabinet.office.id,
                });
                if (!office) {
                    offices.push({
                        id: cabinet.office.id,
                        name: cabinet.office.name,
                    });
                }
                cabinets.push({
                    id: cabinet.id,
                    name: cabinet.name,
                    officeId: cabinet.office.id,
                    office: cabinet.office,
                    weight: cabinet.weight,
                });
            });
            cabinets.sort((a, b) => {
                if (a.weight === b.weight) {
                    return a.name.localeCompare(b.name);
                }
                return (a.weight - b.weight);
            });

            this.setState({
                offices,
                cabinets,
            });

            if (offices.length) {
                let date = this.getFromCookie("date");
                if (!date) {
                    date = moment().format("YYYY-MM-DD");
                }

                let officeId = parseInt(this.getFromCookie("officeId"));
                let office = _.find(offices, {
                    id: officeId
                })
                if (!office) {
                    office = offices[0];
                }
                this.changeFilter(office.id, date)
            }
        }).catch(() => {
            this.props.addAlert("Что-то пошло не так");
        }).finally(() => {
            this.props.setShowLoad(false);
        });
    }

    changeFilter = (officeId, date) => {
        this.saveInCookie("officeId", officeId);
        this.saveInCookie("date", date);

        this.setState({
            officeId: officeId,
            selectSlots: [],
            date: date,
        });
        this.props.setShowLoad(true);

        this.props.listCalendarAction(officeId, date).then(response => {
            if (response.value.nextDate) {
                this.changeFilter(officeId, response.value.nextDate);
            } else {
                this.setState({
                    calendar: response.value,
                })
            }
        }).catch(() => {
            this.props.addAlert("Что-то пошло не так");
        }).finally(() => {
            this.props.setShowLoad(false);
        });
    }

    toggleSlot(cabinet, slot, price) {
        let selectSlots = this.state.selectSlots;
        let slotIndex = _.findIndex(selectSlots, {
            cabinetId: cabinet.id,
            hour: slot.hour,
            minute: slot.minute,
        });

        if (slotIndex === -1) {
            selectSlots.push({
                cabinet: cabinet,
                cabinetId: cabinet.id,
                hour: slot.hour,
                minute: slot.minute,
                slot: slot,
                price: price,
                date: this.state.date,
            });
        } else {
            _.pullAt(selectSlots, [slotIndex])
        }

        this.setState({
            selectSlots
        });
    }

    renderSlot(cabinet, slot, index) {
        if (!this.state.calendar) {
            return <TableCell className={"n-" + (index + 2) + " lock"} key={cabinet.id} align="center">-</TableCell>;
        }

        let bookings = this.state.calendar.bookings;

        let prices = this.state.calendar.prices;

        let booking = _.find(bookings, {
            cabinetId: cabinet.id,
            hour: slot.hour,
            minute: slot.minute,
        });

        let price = _.find(prices, {
            cabinetId: cabinet.id,
            hour: slot.hour,
        });

        if (booking && booking.isOwner) {
            return <TableCell style={{
                cursor: booking.canCancel ? "pointer" : "initial",
            }} onClick={() => {
                if (booking.canCancel) {
                    this.props.setCancelBooking(booking);
                }
            }} className={"calendar-page__items--item__slot owner"} key={cabinet.id} align="center">
                Мое бронирование
            </TableCell>;
        } else if (booking) {
            return <TableCell className={"n-" + (index + 2) + " lock"} key={cabinet.id} align="center">Занято</TableCell>;
        } else if (!price) {
            return <TableCell className={"n-" + (index + 2) + " lock"} key={cabinet.id} align="center">-</TableCell>;
        } else {

            let hasSelect = _.find(this.state.selectSlots, {
                cabinetId: cabinet.id,
                hour: slot.hour,
                minute: slot.minute,
            });

            return <TableCell style={{
                cursor: "pointer",
                position: "relative",
                padding: 0,
                userSelect: "none",
            }} className={"n-" + (index + 2) + (hasSelect ? " bg-green" : "")} key={cabinet.id} align="center" onClick={() => {
                if (!this.state.stateHold) {
                    this.toggleSlot(cabinet, slot, price);
                }
                this.setState({
                    stateHold: false,
                });
            }}>
                <ClickNHold style={{
                    width: "100%",
                    height: "100%",
                }} time={1.4} onClickNHold={() => {
                    if (hasSelect) {
                        this.setState({
                            stateHold: true,
                        });
                        this.props.setLockBookings(this.state.selectSlots);
                    }
                }}>
                    <div style={{
                        padding: "6px 16px",
                        userSelect: "none",
                    }}>
                        <span className={"calendar-page__items--item__slot--info__price"}>{ price.price } руб.</span>
                        <br />
                        <span className={"calendar-page__items--item__slot--info__cancel-time"}>Отмена за { price.cancelTime }</span>
                    </div>
                </ClickNHold>
            </TableCell>
        }
    }

    renderWeek() {
        let week = moment(this.state.date).format("e");

        if (week === "0") {
            return "Воскресенье";
        } else if (week === "1") {
            return "Понедельник";
        } else if (week === "2") {
            return "Вторник";
        } else if (week === "3") {
            return "Среда";
        } else if (week === "4") {
            return "Четверг";
        } else if (week === "5") {
            return "Пятница";
        } else if (week === "6") {
            return "Суббота";
        }
    }

    render() {
        return <div className={"calendar-page"}>
            { !!this.props.userInfo
                ? <Card className={"calendar-page__items--item"}>
                    <CardContent>
                        <Grid key={"top"} xs={12} md={12} lg={12} item>
                            <Grid container justify="flex-start" spacing={3}>
                                <Grid key={"left"} xs={12} md={6} lg={6} item>
                                    Здравствуйте, <br /> <b>{ this.props.userInfo.useName }</b>
                                    <br />
                                    <br />
                                    Ваш баланс: <b>{ this.props.userInfo.balance } руб.</b>
                                    <br />
                                    Ваша персональная скидка: <b>{ this.props.userInfo.sale }%</b>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                : null
            }

            <Card className={"calendar-page__items--item"}>
                <CardContent>
                    <Grid key={"top"} xs={12} md={12} lg={12} item>
                        <Grid container justify="flex-start" spacing={3}>
                            <Grid key={"left"} xs={12} md={6} lg={6} item>
                                <Grid container justify="flex-start" spacing={3} >
                                    <Grid key={"date"} xs={12} md={12} lg={12} item>
                                        <FormControl fullWidth>
                                            <InputLabel key={"label"}>Офис</InputLabel>
                                            <Select
                                                key={"select"}
                                                value={this.state.officeId}
                                                onChange={(event) => {
                                                    this.changeFilter(event.target.value, this.state.date);
                                                }}>
                                                { this.state.offices.map(office => {
                                                    return <MenuItem value={office.id} key={office.id}>{office.name}</MenuItem>
                                                }) }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid key={"right"} xs={12} md={6} lg={6} item>
                                <Grid container justify="flex-start" spacing={3}>
                                    <Grid key={"date"} xs={12} md={12} lg={12} item style={{
                                        display: "flex",
                                    }} className={"calendar-page__items--item__date"}>
                                        <IconButton onClick={() => {
                                            this.changeFilter(this.state.officeId, moment(this.state.date).add(-1,'days').format("YYYY-MM-DD"));
                                        }}>
                                            <KeyboardArrowLeftIcon />
                                        </IconButton>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                                            <DatePicker
                                                fullWidth
                                                label={"Дата"}
                                                value={this.state.date}
                                                error={false}
                                                helperText={""}
                                                onChange={(value) => {
                                                    this.changeFilter(this.state.officeId, moment(value).format("YYYY-MM-DD"));
                                                }}
                                                format={"dd.MM.yyyy"}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position={"start"}>
                                                            <Event />
                                                        </InputAdornment>
                                                    )
                                                }}
                                                cancelLabel={"Отмена"}
                                            />
                                        </MuiPickersUtilsProvider>
                                        <IconButton onClick={() => {
                                            this.changeFilter(this.state.officeId, moment(this.state.date).add(1,'days').format("YYYY-MM-DD"));
                                        }}>
                                            <KeyboardArrowRightIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card className={"calendar-page__items--item"} style={{
                overflow: "auto",
            }}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell align={"center"}>
                                {moment(this.state.date).format("DD.MM.YYYY")}
                                <br />
                                {this.renderWeek()}
                            </TableCell>
                            { _.filter(this.state.cabinets, {
                                officeId: this.state.officeId
                            }).map((cabinet, index) => {
                                if (cabinet.officeId !== this.state.officeId) {
                                    return null;
                                }
                                return <TableCell className={"n-" + (index + 2)} key={cabinet.id} align="center">{cabinet.name}</TableCell>
                            }) }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { this.hoursWithMinutes.map(slot => {
                            return <TableRow key={slot.hour + "-" + slot.minute}>
                                <TableCell style={{
                                    whiteSpace: "nowrap",
                                }} component="th" scope="row">
                                    {slot.hour}{slot.minute === "1/2" ? <sup><b>00</b></sup> : <sup><b>30</b></sup>}
                                    <span> - </span>
                                    {slot.hour + (slot.minute === "1/2" ? 0 : 1 )}
                                    {slot.minute === "1/2" ? <sup><b>30</b></sup> : <sup><b>00</b></sup>}
                                </TableCell>
                                { _.filter(this.state.cabinets, {
                                    officeId: this.state.officeId
                                }).map((cabinet, index) => {
                                    return this.renderSlot(cabinet, slot, index);
                                }) }
                            </TableRow>
                        }) }
                    </TableBody>
                </Table>
            </Card>
            { this.state.selectSlots.length > 0
                ? <div className={"calendar-page__lock-info"}>
                    Для бронирования удерживайте ячейку 2 секунды.
                </div>
                : null
            }
        </div>
    }
}

CalendarPage.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

CalendarPage.defaultProps = {
    id: null
};

const mapStateToProps = (state) => {
    return {
        userInfo: state.authReducer.userInfo,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        addAlert: bindActionCreators(actionsCore.addAlert, dispatch),
        setLink: bindActionCreators(actionsCore.setLink, dispatch),
        setShowLoad: bindActionCreators(actionsCore.setShowLoad, dispatch),
        setShowReplenishBalance: bindActionCreators(actionsCore.setShowReplenishBalance, dispatch),
        setShowRedirectProfile: bindActionCreators(actionsCore.setShowRedirectProfile, dispatch),
        setCancelBooking: bindActionCreators(actionsCore.setCancelBooking, dispatch),
        setLockBookings: bindActionCreators(actionsCore.setLockBookings, dispatch),

        listCabinetAction: bindActionCreators(actionsCabinet.listAction, dispatch),
        listCalendarAction: bindActionCreators(actionsCalendar.listAction, dispatch),
    }
};

export default (connect(mapStateToProps, mapDispatchToProps)(CalendarPage))
