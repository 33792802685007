import {fetching} from "./index";

import * as routesApi from "../constants/api"

export function bookingList(skip) {
    return fetching("BOOKING_LIST", routesApi.booking.list.replace(":skip", skip));
}

export function bookingHistory(skip) {
    return fetching("BOOKING_HISTORY", routesApi.booking.history.replace(":skip", skip));
}

export function bookingCancel(id) {
    return fetching("BOOKING_CANCEL", routesApi.booking.cancel.replace(":id", id));
}

export function bookingLock(cabinetId, date, hour, minute) {
    return fetching("BOOKING_TIME_LINE",routesApi.booking.lock, {
        form: {
            cabinetId: cabinetId,
            date: date,
            hour: hour,
            minute: minute,
        }
    });
}