let defaultState = {
    link: location.pathname + location.search,
    alert: null,
    load: false,
    cancelBooking: null,
    lockBookings: null,
    feedBack: false,
    bookingMode: "",
    clearCurrent: null,
    showReplenishBalance: null,
    showRedirectProfile: null,
    showLogout: null,
};

const CoreReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "SET_LINK": {
            let link = action.link;
            return {
                ...state,
                link
            };
        }
        case "ADD_ALERT": {
            return {
                ...state,
                alert: {
                    message: action.message,
                    view: action.view
                },
            };
        }
        case "SET_SHOW_LOAD": {
            let showLoad = action.showLoad;
            return {
                ...state,
                showLoad,
            };
        }
        case "SET_CANCEL_BOOKING": {
            let cancelBooking = action.cancelBooking;
            return {
                ...state,
                cancelBooking,
            };
        }
        case "SET_LOCK_BOOKINGS": {
            let lockBookings = action.lockBookings;
            return {
                ...state,
                lockBookings,
            };
        }
        case "SET_CLEAR_CURRENT": {
            let clearCurrent = action.clearCurrent;

            return {
                ...state,
                clearCurrent,
            };
        }
        case "SET_SHOW_REPLENISH_ACCOUNT": {
            let showReplenishBalance = action.showReplenishBalance;

            return {
                ...state,
                showReplenishBalance,
            };
        }
        case "SET_SHOW_REDIRECT_PROFILE": {
            let showRedirectProfile = action.showRedirectProfile;

            return {
                ...state,
                showRedirectProfile,
            };
        }
        case "SET_SHOW_LOGOUT": {
            let showLogout = action.showLogout;

            return {
                ...state,
                showLogout,
            };
        }
        default:
            return state;
    }
};

export default CoreReducer;
